<template>
  <div>
    <div v-if="!$store.state.isMobile">
      <carousel
        :films="currentFilms"
        v-if="currentFilms && currentFilms.length > 0"
        @goToComingSoonFilms="goToComingSoonFilmsHandler"
      />
      <timeline :films="comingSoonFilms" id="coming-soon-films" />
    </div>
    <div v-else>
      <mobile-image :films="currentFilms" is-todays-film />
      <mobile-image :films="comingSoonFilms" :width="300" class="mt-12" />
    </div>
    <v-overlay :value="showOverlay">
      <v-progress-circular color="red" size="100" indeterminate :opacity="1" />
    </v-overlay>
  </div>
</template>

<script>
import db from '../firebaseConfiguration/firebaseInit';
import moment from 'moment';
import Carousel from '../components/homeComponents/desktopComponents/Carousel';
import Timeline from '../components/homeComponents/desktopComponents/Timeline';
import MobileImage from '../components/homeComponents/mobileComponents/MobileImage';

export default {
  components: { Carousel, Timeline, MobileImage },
  data() {
    return {
      currentFilms: [],
      comingSoonFilms: [],
      showOverlay: true,
    };
  },
  created() {
    this.getCurrentFilms();
  },
  methods: {
    getCurrentFilms() {
      let currentDate = moment().format('YYYY-MM-DD');
      let filmsRef = db.firestore().collection('film');
      filmsRef.where('startDate', '<=', currentDate);
      filmsRef
        .where('endDate', '>=', currentDate)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const datesOfTheCurrentWeek = [];
            currentDate = moment();
            let currentDayOfYear = currentDate.dayOfYear();

            doc.data().dates.forEach((date) => {
              let controlDate = new Date(1970, 0, 1);
              controlDate.setSeconds(date.day.seconds);
              let momentControlDate = moment(controlDate);
              let controlDayOfYear = momentControlDate.dayOfYear();

              if (
                controlDayOfYear >= currentDayOfYear &&
                controlDayOfYear <= currentDayOfYear + 4
              ) {
                datesOfTheCurrentWeek.push(date);
              }
            });

            if (new Date(doc.data().startDate) < new Date(currentDate.toString())) {
              const data = {
                id: doc.data().id,
                description: doc.data().description,
                type: doc.data().type,
                image: doc.data().image,
                length: doc.data().length,
                title: doc.data().title,
                startDate: doc.data().startDate,
                endDate: doc.data().endDate,
                posterImage: doc.data().backgroundImage,
                dates: datesOfTheCurrentWeek,
                cinelandia: doc.data().cinelandia,
              };
              this.currentFilms.push(data);
            }
          });
          this.getComingSoonFilms();
        });
    },
    getTodayFormatted() {
      let date = new Date().getFullYear() + '-';
      date = date + '-';
      if (new Date().getMonth() + 1 < 10) {
        date = date + '0';
      }
      date = date + new Date().getMonth() + 1 + '-';
      if (new Date().getDate() < 10) {
        date = date + '0';
      }
      date = date + new Date().getDate();
      return date;
    },
    getComingSoonFilms() {
      db.firestore()
        .collection('film')
        .where('startDate', '>', moment().format('YYYY-MM-DD'))
        .orderBy('startDate')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const datesOfTheCurrentWeek = [];
            let todaysDate = moment();
            let todaysDayOfYear = todaysDate.dayOfYear();

            doc.data().dates.forEach((date) => {
              let controlDate = new Date(1970, 0, 1);
              controlDate.setSeconds(date.day.seconds);
              let momentControlDate = moment(controlDate);
              let controlDayOfYear = momentControlDate.dayOfYear();

              if (controlDayOfYear >= todaysDayOfYear && controlDayOfYear <= todaysDayOfYear + 4) {
                datesOfTheCurrentWeek.push(date);
              }
            });

            const data = {
              id: doc.data().id,
              description: doc.data().description,
              genre: doc.data().genre,
              image: doc.data().image,
              length: doc.data().length,
              title: doc.data().title,
              startDate: doc.data().startDate,
              endDate: doc.data().endDate,
              posterImage: doc.data().backgroundImage,
              dates: datesOfTheCurrentWeek,
              cinelandia: doc.data().cinelandia,
            };
            this.comingSoonFilms.push(data);
          });
          this.showOverlay = false;
        });
    },
    goToComingSoonFilmsHandler() {
      this.$vuetify.goTo('#coming-soon-films', {
        duration: 1500,
        offset: 0,
        easing: 'easeInOutCubic',
      });
    },
  },
};
</script>

<style scoped>
#home_content .v-speed-dial {
  position: absolute;
}

#home_content .v-btn--floating {
  position: relative;
}
</style>
