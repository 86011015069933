<template>
  <v-main id="carousel_component" class="mb-3">
    <v-carousel
      id="v_carousel"
      hide-delimiters
      hide-delimiter-background
      show-arrows-on-hover
      :cycle="cycle"
    >
      <v-carousel-item v-for="film in films" :key="film.id">
        <v-img :src="film.posterImage" style="border-radius: 0">
          <v-sheet
            class="d-flex justify-start image-sheet"
            color="transparent"
            style="border-radius: 0; position: relative"
          >
            <div class="pl-6 ml-0 mb-6 align-self-end" style="text-align: left">
              <v-row>
                <v-col cols="3">
                  <v-img
                    :src="film.image"
                    style="cursor: pointer"
                    width="600"
                    @click="showFilmDetailsDialog(film)"
                    @mouseleave="isHovered = false"
                    @mouseover="isHovered = true"
                  >
                    <v-overlay v-model="isHovered" absolute color="black">
                      <span class="body-1">
                        <span>DETTAGLI</span>
                        <v-icon class="ml-2">mdi-magnify</v-icon>
                      </span>
                    </v-overlay>
                  </v-img>
                </v-col>
                <v-col cols="9" class="d-flex justify-start pl-0">
                  <div class="align-self-end">
                    <div style="width: 300px">
                      <h1 class="display-1 font-weight-light white--text">
                        {{ film.title }}
                      </h1>
                    </div>
                    <h1 class="headline font-weight-thin white--text">
                      dal {{ film.startDate }} al {{ film.endDate }}
                    </h1>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="align-self-start mr-5 mt-5" style="width: 100%">
              <v-img src="@/assets/cinemaccademia_logo.png" width="200" class="float-right">
              </v-img>
            </div>
            <v-tooltip top nudge-top="0" color="rgba(128, 137, 173, 0.4)">
              <template #activator="{ on }">
                <v-btn
                  absolute
                  bottom
                  style="right: 49%; bottom: 1rem"
                  small
                  fab
                  color="rgba(128, 137, 173, 0.4)"
                  class="white--text"
                  v-on="on"
                  @click="$emit('goToComingSoonFilms')"
                >
                  <v-icon> mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              Prossimamente
            </v-tooltip>
          </v-sheet>
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <film-details
      :show-dialog="showDialog"
      :film-details="selectedFilmDetails"
      @closeDetailsDialog="closeDetailsDialog"
    />
  </v-main>
</template>

<script>
import FilmDetails from '../FilmDetails';

export default {
  components: {
    FilmDetails,
  },
  props: {
    films: undefined,
  },
  data() {
    return {
      showDialog: false,
      selectedFilmDetails: {},
      cycle: true,
      isHovered: false,
    };
  },
  methods: {
    showFilmDetailsDialog(selectedFilm) {
      this.cycle = false;
      this.showDialog = true;
      this.selectedFilmDetails = Object.assign({}, selectedFilm);
    },
    closeDetailsDialog(value) {
      this.cycle = true;
      this.showDialog = value;
    },
  },
};
</script>

<style>
#carousel_component {
  height: 100vh;
}

#v_carousel {
  height: 100% !important;
}

.v-carousel__item {
  height: 100% !important;
}

.image-sheet {
  height: 100vh;
  background-image: linear-gradient(rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1));
}
</style>
