<template>
  <div>
    <span class="title white--text">{{ heading }}</span>
    <div class="d-flex overflow-x-auto">
      <v-img
        v-for="(film, index) in films"
        :key="film.id"
        :src="film.image"
        :max-width="width !== undefined ? width : ''"
        :class="{ 'ml-2': index !== 0 }"
        @click="openFilmDetails(film)"
      >
        <v-sheet
          color="transparent"
          :class="isTodaysFilm ? 'image-sheet d-flex pa-3' : 'image-sheet-coming-soon d-flex pa-3'"
        >
          <v-chip v-if="isTodaysFilm" small label class="green"> In programmazione </v-chip>
          <v-chip v-else x-small label class="orange darken-2"> Prossimamente </v-chip>
          <v-chip
            :x-small="!isTodaysFilm"
            :small="isTodaysFilm"
            class="ml-2 yellow darken-2"
            label
            v-if="film.cinelandia"
            >Cinelandia</v-chip
          >
          <v-chip
            v-for="(hour, index) in getTodaysSchedule(film.dates)"
            :key="index"
            dark
            small
            label
            class="grey darken-3 white--text ml-2 align-self-start"
          >
            {{ hour }}
          </v-chip>
        </v-sheet>
      </v-img>
    </div>
    <film-details
      :show-dialog="showDialog"
      :film-details="selectedFilm"
      @closeFilmDialog="showDialog = false"
    />
    <film-details-mobile
      :show-dialog="showDialogMobile"
      :film-details="selectedFilm"
      @closeFilmDialog="showDialogMobile = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
/*eslint-disable */
import FilmDetails from '../FilmDetails';
import FilmDetailsMobile from '../FilmDetailsMobile';

export default {
  components: {
    FilmDetailsMobile,
    FilmDetails,
  },
  props: {
    films: { type: Array },
    heading: '',
    width: undefined,
    isTodaysFilm: { type: Boolean, default: false },
  },
  data() {
    return {
      showDialog: false,
      showDialogMobile: false,
      selectedFilm: {},
    };
  },
  methods: {
    openFilmDetails(film) {
      Object.assign(this.selectedFilm, film);
      if (this.$store.state.isMobile) this.showDialogMobile = true;
      else this.showDialog = true;
    },
    getTodaysSchedule(dates) {
      let todaysSchedule = [];
      dates.forEach((date) => {
        let todayDate = new Date(1970, 0, 1);
        todayDate.setSeconds(date.day.seconds);
        let todayMoment = moment(todayDate).format('DD-MM-YYYY');

        if (todayMoment === moment().format('DD-MM-YYYY')) {
          date.hours.forEach((hour) => {
            todaysSchedule.push(hour.value);
          });
        }
      });
      return todaysSchedule;
    },
    checkIfIsTodaysFilm(startDate, endDate) {
      startDate = moment(startDate);
      endDate = moment(endDate);
      let currentDate = moment();
      if (currentDate >= startDate && currentDate <= endDate) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.image-sheet {
  height: 100%;
  background-image: linear-gradient(rgba(40, 40, 40, 0.1), rgba(40, 40, 40, 1));
}

.image-sheet-coming-soon {
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
</style>
