<template v-touch="{ left: () => closeDialog }">
  <v-dialog v-model="show" transition="dialog-bottom-transition" fullscreen>
    <v-card style="background-color: rgba(0, 0, 0, 0.9)" class="white--text" tile>
      <v-card-title class="pa-0">
        <v-img :src="filmDetails.posterImage">
          <v-sheet
            class="transparent white--text d-flex justify-end pa-3 image-sheet"
            style="width: 100%; height: 100%; border-radius: 0"
          >
            <span class="title font-weight-thin">{{ filmDetails.title }}</span>
          </v-sheet>
        </v-img>
      </v-card-title>
      <v-card-subtitle>
        <v-img
          :src="filmDetails.image"
          max-width="80"
          class="ml-5 elevation-10"
          contain
          style="position: absolute !important; margin-top: -120px; border-radius: 0"
        />
      </v-card-subtitle>
      <v-card-text class="white--text pt-6 font-weight-thin body-1 fill-height">
        <div v-for="(date, dateIndex) in filmDetails.dates" :key="dateIndex">
          <v-row class="mb-n3">
            <v-col cols="5">
              <span>
                {{ getSchedules(date) }}
              </span>
            </v-col>
            <v-col cols="7">
              <span v-for="(hour, index) in date.hours" :key="hour.id">
                <v-chip outlined dark label small :class="{ 'ml-2': index !== 0 }">{{
                  hour.value
                }}</v-chip>
              </span>
            </v-col>
          </v-row>
        </div>
        <div class="mt-5">
          <span>
            {{ filmDetails.description }}
          </span>
        </div>
        <v-btn absolute top left dark text icon class="ma-0 pa-0" @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: { type: Boolean, default: false },
    filmDetails: { type: Object },
  },
  data() {
    return {
      show: this.showDialog,
      filmTabs: null,
      giorniSettimana: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      mesi: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
    };
  },
  watch: {
    showDialog() {
      this.show = this.showDialog;
    },
    show() {
      if (!this.show) this.$emit('closeFilmDialog');
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
    },
    getDateFromMilliseconds(seconds) {
      let date = new Date(1970, 0, 1);
      date.setSeconds(seconds);
      let giornoSettimana = this.giorniSettimana[date.getDay()];
      let mese = this.mesi[date.getMonth()];
      return giornoSettimana + ' ' + date.getDate() + ' ' + mese;
    },
    getTitleDate(dateString) {
      if (dateString != null && dateString.length > 0) {
        var dateArray = dateString.split('-');
        return 'dal ' + dateArray[2] + ' ' + this.mesi[dateArray[1] - 1];
      }
      return null;
    },
    getSchedules(date) {
      let parsedDate = new Date(1970, 0, 1);
      parsedDate.setSeconds(date.day.seconds);

      return parsedDate.getDate() + ' ' + this.mesi[parsedDate.getMonth()];
    },
  },
};
</script>

<style scoped>
#film_details_close_icon {
  cursor: pointer;
}

.image-sheet {
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
}
</style>
