<template>
  <v-container class="d-flex flex-column align-center justify-center">
    <!--    <v-card-->
    <!--      width="30%"-->
    <!--      class="py-3 mb-0 display-1 white&#45;&#45;text d-flex justify-center"-->
    <!--      style="border-style: solid; border-color: rgba(128, 137, 173, 0.7); border-width: 2px ;background-color: transparent"-->
    <!--      outlined-->
    <!--    >-->
    <!--      Prossimamente-->
    <!--    </v-card>-->
    <!--            color="transparent"-->

    <div v-for="(film, index) in films" :key="film.id" style="width: 100% ;color: rgb(159 159 159);" @click="showFilmDetailsDialog(film)" >
<!--      <v-divider class="mb-3" dark></v-divider>-->
      <v-row>
        <v-col cols="2">
          <v-img
              :src="film.image"
              class="elevation-0 flex-grow-0"
              gradient="to left, rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1)"
              max-width="150"
              style="border-radius: 0"
          />
        </v-col>
        <v-col>
          <v-row>
            <v-col><h2 class="white--text" >{{ film.title.toUpperCase() }}</h2> </v-col>
          </v-row>
          <v-row>
            <v-col style="width: 100%"><b class="white--text">DESCRIZIONE :</b> {{ film.description }}</v-col>
          </v-row>
          <div v-for="(date,index) in film.dates.slice(0,2)">
            <v-row align="start">
              <v-col cols="2" class="white--text"><b>{{ getTitleDateFromTimestamp(date.day).toUpperCase() }}</b></v-col>
                <div v-for="(hours,index) in date.hours" :key="index" class="mt-2 mb-2">
                  <v-chip outlined medium label color="white" class=" white--text">
                    {{ hours.value }}
                  </v-chip>
                </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row style="text-align: center">
        <v-col>
          <h3 style="cursor: pointer">
          VEDI DETTAGLI FILM
          </h3>
        </v-col>
      </v-row>
      <v-divider class="mt-3" dark></v-divider>
    </div>

    <!--    <v-timeline dark>-->
    <!--      <v-timeline-item-->
    <!--        v-for="(film, index) in films"-->
    <!--        :key="film.id"-->
    <!--        color="rgba(128, 137, 173, 0.7)"-->
    <!--        dark-->
    <!--        small-->
    <!--        fill-dot-->
    <!--      >-->
    <!--        <template v-slot:opposite>-->
    <!--              <timeline-item-card-->
    <!--            ref="timelineItemCard"-->
    <!--            :film="film"-->
    <!--            :film-index="index"-->
    <!--            @openFilmDetails="showFilmDetailsDialog(film)"-->
    <!--          />-->
    <!--        </template>-->
    <!--      </v-timeline-item>-->
    <!--    </v-timeline>-->
    <film-details
        :show-dialog="showDialog"
        :film-details="selectedFilmDetails"
        @closeDetailsDialog="closeDetailsDialog"
    />
  </v-container>
</template>

<script>
import FilmDetails from '../FilmDetails';
import TimelineItemCard from './TimelineItemCard';
import {DateUtils} from '../../../util/DateUtils';
// import {Timestamp} from "@firebase/firestore-types";

export default {
  // computed: {
  //   Timestamp() {
  //     return Timestamp
  //   }
  // },
  components: {
    TimelineItemCard,
    FilmDetails,
  },
  props: {
    films: undefined,
  },
  mixins: [DateUtils],
  data() {
    return {
      hoverIcon: true,
      selectedFilmDetails: {},
      showDialog: false,
    };
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        if (this.$refs.timelineItemCard && this.$refs.timelineItemCard.length > 0) {
          this.$refs.timelineItemCard.forEach((timelineItemCard) => {
            timelineItemCard.onFilmDetailCloseHandler();
          });
        }
      }
    },
  },
  methods: {
    showFilmDetailsDialog(selectedFilm) {
      this.showDialog = true;
      this.selectedFilmDetails = Object.assign({}, selectedFilm);
    },
    closeDetailsDialog(value) {
      this.cycle = true;
      this.showDialog = value;
    },
  },
};
</script>

<style>
#details-icon {
  color: white;
}

#details-icon:hover {
  color: black;
}
</style>
