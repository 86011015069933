<template>
  <v-card
    elevation="2"
    rounded
    class="overflow-hidden timeline-item-card"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click="$emit('openFilmDetails')"
    width="1200"
  >
    <div v-if="filmIndex % 2 === 0" class="d-flex justify-start">
      <v-img
        :src="film.image"
        class="elevation-0 flex-grow-0"
        gradient="to right, rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1)"
        max-width="100"
        style="border-radius: 0"
      />
      <v-sheet
        class="px-4 pb-2 pt-4 d-flex flex-column justify-space-between flex-grow-1"
        color="rgba(20, 20, 20, 1)"
        style="border-radius: 0"
      >
        <span class="grey--text text--lighten-1 headline">{{
          getFilmDescription(film.title)
        }}</span>
        <span class="title grey--text text--lighten-3">{{ getTitleDate(film.startDate) }}</span>
      </v-sheet>
    </div>
    <div v-else class="d-flex justify-start">
      <v-sheet
        class="px-4 pb-2 pt-4 d-flex flex-column justify-space-between flex-grow-1"
        color="rgba(20, 20, 20, 1)"
        style="border-radius: 0"
      >
        <span class="grey--text text--lighten-1 headline">{{
          getFilmDescription(film.title)
        }}</span>
        <span class="title grey--text text--lighten-3">{{ getTitleDate(film.startDate) }}</span>
      </v-sheet>
      <v-img
        :src="film.image"
        class="elevation-0 flex-grow-0"
        gradient="to left, rgba(20, 20, 20, 0.1), rgba(20, 20, 20, 1)"
        max-width="100"
        style="border-radius: 0"
      />
    </div>
    <v-overlay v-model="isHovered" absolute color="black">
      <span class="display-1">
        <span>DETTAGLI</span>
        <v-icon x-large class="ml-2">mdi-magnify</v-icon>
      </span>
    </v-overlay>
  </v-card>
</template>

<script>
import { DateUtils } from '../../../util/DateUtils';

export default {
  name: 'TimelineItemCard',
  mixins: [DateUtils],
  props: {
    film: undefined,
    filmIndex: undefined,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    getFilmDescription(description) {
      if (description.length > 200) {
        return description.slice(0, 200) + '...';
      } else {
        return description;
      }
    },
    onFilmDetailCloseHandler() {
      this.isHovered = false;
    },
  },
};
</script>

<style scoped>
.timeline-item-card::before {
  background-color: transparent;
}
</style>
