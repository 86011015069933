export const DateUtils = {
    data() {
        return {
            weekDays: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            months: [
                "Gennaio",
                "Febbraio",
                "Marzo",
                "Aprile",
                "Maggio",
                "Giugno",
                "Luglio",
                "Agosto",
                "Settembre",
                "Ottobre",
                "Novembre",
                "Dicembre"
            ]
        }
    },
    methods: {
        getTitleDate(dateString) {
            if (dateString != null && dateString.length > 0) {
                let dateArray = dateString.split("-");
                return "dal " + dateArray[2] + " " + this.months[dateArray[1] - 1];
            }
            return null;
        },
        getTitleDateFromTimestamp(timestamp) {
            let date = new Date(timestamp.seconds * 1000);
            let options = {  year: 'numeric', month: 'numeric', day: 'numeric' ,weekday: 'long'};
            let dateString = date.toLocaleDateString("IT",options);
            return dateString;
            // if (dateString != null && dateString.length > 0) {
            //     let dateArray = dateString.split("-");
            //     return "dal " + dateArray[2] + " " + this.months[dateArray[1] - 1];
            // }
            // return null;
        }

    }
}
