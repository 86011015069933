<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card
      style="background-color: rgba(0, 0, 0, 0.9);"
      class="white--text"
      tile
    >
      <v-system-bar
        v-if="!$store.state.isMobile"
        color="transparent"
        window
      >
        <v-spacer />
        <v-icon
          id="film_details_close_icon"
          class="white--text"
          @click="show = false"
        >
          mdi-close
        </v-icon>
      </v-system-bar>
      <v-card-title class="headline font-weight-light d-flex justify-space-between">
        <span>{{ filmDetails.title }}</span>
        <span>{{ getTitleDate(filmDetails.startDate) }}</span>
      </v-card-title>
      <v-card-text class="white--text">
        <v-row>
          <v-col cols="3">
            <v-img
              :src="filmDetails.image"
              max-width="300"
              style="border-radius: 0;"
            />
          </v-col>
          <v-col
            cols="9"
            class="body-1 font-weight-thin"
          >
            <v-tabs
              v-model="filmTabs"
              background-color="transparent"
              color="white"
              grow
            >
              <v-tab class="white--text">
                Informazioni
              </v-tab>
              <v-tab class="white--text">
                Orari
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="filmTabs"
              style="background-color: transparent !important;"
            >
              <v-tab-item class="white--text">
                <v-container>
                  <v-row
                    v-if="filmDetails['length'] != null"
                    class="d-flex flex-column"
                  >
                    <span class="title">Durata</span>
                    <h3 class="font-weight-light">{{ filmDetails.length }}</h3>
                  </v-row>
                  <v-row
                    v-if="filmDetails['type'] != null"
                    class="d-flex flex-column"
                    :class="{'mt-3': filmDetails.length }"
                  >
                    <span class="title">Genere</span>
                    <h3 class="font-weight-light">{{ filmDetails.type }}</h3>
                  </v-row>
                  <v-row
                    v-if="filmDetails['description'] != null"
                    class="d-flex flex-column"
                    :class="{'mt-3': filmDetails.type || filmDetails.length }"
                  >
                    <span class="title">Descrizione</span>
                    <h3 class="font-weight-light">{{ filmDetails.description }}</h3>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container class="d-flex flex-column">
                  <v-row
                    v-for="(date, index) in filmDetails.dates"
                    :key="index"
                    style="border-bottom: 1px solid rgba(255, 255, 255, 0.3);"
                  >
                    <v-col cols="3" class="d-flex align-center">
                      <h3 class="text-uppercase font-weight-regular">
                        {{ getDateFromMilliseconds(date.day.seconds) }}
                      </h3>
                    </v-col>
                    <v-col
                      v-for="hour in date.hours"
                      :key="hour.id"
                      cols="1"
                      class="d-flex align-center"
                    >
                      <v-chip
                        outlined
                        color="white"
                        label
                        class="font-weight-bold"
                      >
                        {{ hour.value }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {DateUtils} from "../../util/DateUtils";

export default {
  mixins: [DateUtils],
  props: {
    showDialog: {type: Boolean, default: false},
    filmDetails: {type: Object, default: undefined}
  },
  data() {
    return {
      show: this.showDialog,
      filmTabs: null
    };
  },
  watch: {
    showDialog() {
      this.show = this.showDialog
    },
    show() {
      if (!this.show) this.$emit("closeDetailsDialog");
    }
  },
  methods: {
    getDateFromMilliseconds(seconds) {
      let date = new Date(1970, 0, 1);
      date.setSeconds(seconds);
      let giornoSettimana = this.weekDays[date.getDay()];
      let mese = this.months[date.getMonth()];
      return giornoSettimana + " " + date.getDate() + " " + mese;
    }
  }
};
</script>

<style scoped>
#film_details_close_icon {
  cursor: pointer;
}
</style>
